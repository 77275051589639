import React, { useState } from 'react';
import Box from "@amzn/meridian/box";
import Row from "@amzn/meridian/row";
import UserInput from '../inputFields/UserInput';
import WorkflowDate from '../inputFields/SearchDates';
import { de } from 'date-fns/locale';
import { ActualsParams } from '../../types/actuals';
import { ForecastParams } from '../../types/forecast';

interface ForecastFormProps {
  onParamsChange: (actualsParams: ActualsParams, forecastParams: ForecastParams) => void;
}

const InputForm: React.FC<ForecastFormProps> = ({ onParamsChange }) => {
  // Input states for Actuals Params
  const [asin, setAsin] = useState('B09LNW7L55');
  const [warehouse, setWarehouse] = useState('MAB2');
  const [startDate, setStartDate] = useState('2024-11-07');
  const [endDate, setEndDate] = useState('2024-12-15');

  // Additional input for Forecast Params
  const [fcd, setFcd] = useState('2024-11-20');
  const [modelVersion, setModelVersion] = useState('numpyro-promo-lifts-v1');

  // Update parent component whenever any value changes
  React.useEffect(() => {
    const actualsParams = {
      asin,
      warehouse,
      start_date: startDate,
      end_date: endDate
    };

    const forecastParams = {
      asin,
      warehouse,
      fcd,
      models_versions: modelVersion
    };

    onParamsChange(actualsParams, forecastParams);
  }, [asin, warehouse, startDate, endDate, fcd, modelVersion]);

  return (
    <Box spacingInset="xsmall" >
      <Row alignmentVertical="center">
        <UserInput
          value={asin}
          setValue={setAsin}
          label="ASIN"
          placeholder="Enter ASIN..."
        />
        <UserInput
          value={warehouse}
          setValue={setWarehouse}
          label="Warehouse"
          placeholder="Enter warehouse..."
        />
      </Row>
      <Row>
        <WorkflowDate
          onChangeEvent={setStartDate}
          setDate={startDate}
          label="Start Date"
        />
        <WorkflowDate
          onChangeEvent={setEndDate}
          setDate={endDate}
          label="End Date"
        />
      </Row>
      <Row>
        <WorkflowDate
          onChangeEvent={setFcd}
          setDate={fcd}
          label="FCD"
        />
        <UserInput
          value={modelVersion}
          setValue={setModelVersion}
          label="Model Version"
          placeholder="Enter model version..."
        />
      </Row>
    </Box> 
  );
};

export default InputForm;
