import React, { useEffect, useState } from 'react';
import Box from "@amzn/meridian/box";
import Row from "@amzn/meridian/row";
import Button from '@amzn/awsui-components-react/polaris/button';
import { useForecastAndActuals } from '../../hooks/useForecastAndActuals';
import InputForm from './InputForm';
import ForecastChart from './ForecastChart';
import { ActualsParams, ActualData } from '../../types/actuals';
import { ForecastParams, ForecastData } from '../../types/forecast';
import actualsDataMock from '../../mock/actualDataMock';
import forecastDataMock from '../../mock/forecastDataMock';

const Deepdive: React.FC = () => {
  const [chartParams, setChartParams] = useState<{
    actualsParams: ActualsParams;
    forecastParams: ForecastParams;
  }>({
    actualsParams: {
      asin: 'B09LNW7L55',
      warehouse: 'MAB2',
      start_date: '2024-11-07',
      end_date: '2024-12-15'
    },
    forecastParams: {
      asin: 'B09LNW7L55',
      warehouse: 'MAB2',
      fcd: '2024-11-20',
      models_versions: 'numpyro-promo-lifts-v1'
    }
  });

  const [showChart, setShowChart] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  // Call the hook with the current parameters
  const { 
    actualsData, 
    forecastData, 
    isLoading, 
    error 
  } = useForecastAndActuals(
   chartParams.actualsParams,
   chartParams.forecastParams
  );

  const handleParamsChange = (actualsParams: ActualsParams, forecastParams: ForecastParams) => {
    setChartParams({
      actualsParams,
      forecastParams
    });
    setShowChart(false);
    setIsGenerating(false);
  };

  const handleGenerateChart = () => {
    setShowChart(true);
    setIsGenerating(true);
    console.log('---------------------------------------------------')
    console.log(chartParams.actualsParams, chartParams.forecastParams)
  };

  // Reset generating state when loading completes
  useEffect(() => {
    if (!isLoading && showChart) {
      setIsGenerating(false);
    }
  }, [isLoading, showChart]);

  const areParamsValid = () => {
    const { actualsParams, forecastParams } = chartParams;
    return (
      actualsParams.asin &&
      actualsParams.warehouse &&
      actualsParams.start_date &&
      actualsParams.end_date &&
      forecastParams.asin &&
      forecastParams.warehouse &&
      forecastParams.fcd &&
      forecastParams.models_versions
    );
  };

  return (
    <>
      <Box spacingInset="xsmall">
        <Row alignmentVertical="center">
          <InputForm onParamsChange={handleParamsChange} />
        </Row>
        <Button 
          variant="primary" 
          onClick={handleGenerateChart}
          disabled={!areParamsValid() || isGenerating}
          loading={isGenerating}
        >
          {isGenerating ? 'Generating...' : 'Generate Chart'}
        </Button>
      </Box>

      {showChart && (
        isLoading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error}</div>
        ) : chartParams.actualsParams && chartParams.forecastParams ? (
          <ForecastChart
            actualsData={actualsDataMock}
            forecastData={forecastDataMock}
            asin={chartParams.forecastParams.asin}
            fcd={chartParams.forecastParams.fcd}
          />
        ) : null
      )}
    </>
  );
};


export default Deepdive;