import { useState, useEffect } from 'react';
import { ForecastService } from '../services/forecastService';
import { ForecastData, ForecastParams } from '../types/forecast';

export const useForecast = (params: ForecastParams) => {
  const [data, setData] = useState<ForecastData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await ForecastService.getForecast(params);
        setData(response);
      } catch (error) {
        setError('Failed to fetch forecast data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [params.asin, params.warehouse, params.fcd, params.models_versions]);

  return { data, isLoading, error };
};
