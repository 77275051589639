const actualsDataMock: any = [{"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-09", "units": 11.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-15", "units": 8.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-13", "units": 3.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-12-09", "units": 1.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-24", "units": 14.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-18", "units": 8.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-08", "units": 2.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-22", "units": 10.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-07", "units": 2.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-19", "units": 5.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-16", "units": 7.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-12-11", "units": 10.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-28", "units": 11.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-25", "units": 20.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-12", "units": 8.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-23", "units": 18.0, "instock_raw": 0.833333333}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-26", "units": 36.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-12-02", "units": 2.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-10", "units": 5.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-30", "units": 6.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-12-06", "units": 2.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-11", "units": 1.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-12-07", "units": 3.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-12-10", "units": 3.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-12-03", "units": 4.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-29", "units": 5.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-27", "units": 18.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-14", "units": 8.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-12-14", "units": 11.0, "instock_raw": 1.0},
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-21", "units": 2.0, "instock_raw": 0.291666667}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-17", "units": 10.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-12-12", "units": 3.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-12-15", "units": 15.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-12-04", "units": 2.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-12-08", "units": 9.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-12-05", "units": 2.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-12-13", "units": 12.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-12-01", "units": 10.0, "instock_raw": 1.0}, 
    {"site_id": "MAB2", "business_id": "AFS", "asin": "B09LNW7L55", "order_day": "2024-11-20", "units": 0.0, "instock_raw": 0.583333333}]
export default actualsDataMock;