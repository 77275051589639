import axios, { AxiosError } from 'axios';
import { ForecastData, ForecastParams } from '../types/forecast';

const API_BASE_URL = 'https://bcvrx8lyj8.execute-api.us-east-1.amazonaws.com/jwt';

export class ForecastService {
  static async getForecast(params: ForecastParams): Promise<ForecastData[]> {
    try {
      const response = await axios.get<ForecastData[]>(`${API_BASE_URL}/forecasts`, {
        params: {
          asin: params.asin,
          warehouse: params.warehouse,
          fcd: params.fcd,
          models_versions: params.models_versions
        }
      });

      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error('Error fetching forecast:', error);
      if (axiosError.response) {
        console.error('Status:', axiosError.response.status);
        console.error('Data:', axiosError.response.data);
      }
      throw error;
    }
  }
}