import axios, { AxiosError } from 'axios';
import { ActualData, ActualsParams } from '../types/actuals';

const API_BASE_URL = 'https://bcvrx8lyj8.execute-api.us-east-1.amazonaws.com/jwt';

export class ActualsService {
  static async getActuals(params: ActualsParams): Promise<ActualData[]> {
    try {
      const response = await axios.get<ActualData[]>(`${API_BASE_URL}/actuals`, {
        params: {
          asin: params.asin,
          warehouse: params.warehouse,
          start_date: params.start_date,
          end_date: params.end_date
        }
      });

      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error('Error fetching actuals:', error);
      if (axiosError.response) {
        console.error('Status:', axiosError.response.status);
        console.error('Data:', axiosError.response.data);
      }
      throw error;
    }
  }
}
