import React from 'react';
import {
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryAxis,
  VictoryLabel,
  VictoryLegend,
  VictoryVoronoiContainer
} from 'victory';

interface DataPoint {
  site_id: string;
  business_id: string;
  asin: string;
  order_day: string;
  units?: number;
  instock_raw?: number;
  p50?: number;
  p90?: number;
  model_version?: string;
}

interface ChartProps {
  actualsData: DataPoint[] | any;
  forecastData: DataPoint[] | any;
  asin: string;
  fcd: string;
}

const legendData = [
  { 
    name: "Actuals", 
    symbol: { 
      type: "circle",
      fill: "firebrick" 
    }
  },
  { 
    name: "P50", 
    symbol: { 
      type: "line",
      stroke: "firebrick"
    }
  },
  { 
    name: "P90", 
    symbol: { 
      type: "line",
      stroke: "firebrick"
    }
  }
];

const legendStyle = {
  title: { fontSize: 15 },
  labels: { fontSize: 15 },
  data: {
    strokeWidth: 2
  }
};
const ForecastChart: React.FC<ChartProps> = ({ actualsData, forecastData, asin, fcd }) => {
  // Add null checks for empty data
  if (!actualsData?.length || !forecastData?.length) {
    return <div>No data available</div>;
  }

  // Combine site_id and business_id for display with null check
  const siteLabel = actualsData[0] ? `${actualsData[0].business_id} - ${actualsData[0].site_id}` : '';

  // Combine all dates for domain calculation with safety checks
  const allDates = [
    ...actualsData.filter(d => d?.order_day).map(d => new Date(d.order_day)),
    ...forecastData.filter(d => d?.order_day).map(d => new Date(d.order_day))
  ].filter(date => !isNaN(date.getTime())); // Filter out invalid dates

  // Safety check for empty dates array
  if (allDates.length === 0) {
    return <div>Invalid date data</div>;
  }

  const minDate = new Date(Math.min(...allDates.map(d => d.getTime())));
  const maxDate = new Date(Math.max(...allDates.map(d => d.getTime())));

  // Combine all values for domain calculation with safety checks
  const allValues = [
    ...actualsData.map(d => d?.units || 0),
    ...forecastData.map(d => Math.max(d?.p50 || 0, d?.p90 || 0))
  ].filter(value => !isNaN(value)); // Filter out NaN values

  // Safety check for empty values array
  if (allValues.length === 0) {
    return <div>Invalid numerical data</div>;
  }

  const maxValue = Math.max(...allValues) * 1.2; // Add 20% padding

  return (
    <div style={{ width: '100%', height: '500px' }}>
      <VictoryChart
        width={2000}
        height={500}
        scale={{ x: "time" }}
        domain={{ 
          x: [minDate, maxDate],
          y: [0, maxValue || 1] // Provide fallback for maxValue
        }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => {
              if (!datum?.order_day) return '';
              return `${new Date(datum.order_day).toLocaleDateString()}\n${
                datum.units != null ? `Actual: ${datum.units}` :
                datum.p50 != null ? `P50: ${datum.p50}\nP90: ${datum.p90}` : ''
              }`;
            }}
          />
        }
      >
        <VictoryLabel
          text={`Test ${asin || 'N/A'} for FCD: ${fcd || 'N/A'}`}
          x={1000}
          y={30}
          textAnchor="middle"
          style={{ fontSize: 20 }}
        />

        {/* Site Label */}
        {siteLabel && (
          <VictoryLabel
            text={siteLabel}
            x={100}
            y={50}
            style={{ fontSize: 12, fontWeight: "bold" }}
          />
        )}

        {/* Actuals Points */}
        <VictoryScatter
          data={actualsData.filter(d => d?.units != null && d?.order_day)}
          x={(d) => new Date(d.order_day)}
          y="units"
          style={{
            data: {
              fill: ({ datum }) => {
                const instockColor = datum?.instock_raw ?? 0;
                return `hsl(${instockColor * 120}, 70%, 50%)`;
              },
              stroke: "black",
              strokeWidth: 0.5
            }
          }}
          size={5}
          labels={({ datum }) => datum?.units}
          labelComponent={
            <VictoryLabel dy={-10} />
          }
        />

        {/* P50 Line */}
        <VictoryLine
          data={forecastData.filter(d => d?.p50 != null && d?.order_day)}
          x={(d) => new Date(d.order_day)}
          y="p50"
          style={{
            data: {
              stroke: "firebrick",
              strokeWidth: 1
            }
          }}
        />

        {/* P90 Line */}
        <VictoryLine
          data={forecastData.filter(d => d?.p90 != null && d?.order_day)}
          x={(d) => new Date(d.order_day)}
          y="p90"
          style={{
            data: {
              stroke: "firebrick",
              strokeWidth: 1,
              strokeDasharray: "5,1"
            }
          }}
        />

        {/* X Axis */}
        <VictoryAxis
          tickFormat={(t) => {
            const date = new Date(t);
            return !isNaN(date.getTime()) 
              ? date.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' })
              : '';
          }}
          style={{
            tickLabels: { fontSize: 8, padding: 5 }
          }}
        />

        {/* Y Axis */}
        <VictoryAxis
          dependentAxis
          style={{
            tickLabels: { fontSize: 8, padding: 5 }
          }}
        />

        {/* Legend */}
        <VictoryLegend
          x={50}
          y={450}
          orientation="horizontal"
          gutter={20}
          style={legendStyle}
          data={legendData}
        />
      </VictoryChart>
    </div>
  );
};


export default ForecastChart;
