// src/hooks/useForecast.ts
import { useState, useEffect } from 'react';
import { ForecastService } from '../services/forecastService';
import { ForecastData, ForecastParams } from '../types/forecast';
import { ActualsService } from '../services/actualsService';
import { ActualData, ActualsParams } from '../types/actuals';

export const useActuals = (params: ActualsParams) => {
  const [data, setData] = useState<ActualData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await ActualsService.getActuals(params);
        setData(response);
      } catch (error) {
        setError('Failed to fetch forecast data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [params.asin, params.warehouse, params.start_date, params.end_date]);

  return { data, isLoading, error };
};
