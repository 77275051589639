import * as tokens from '@amzn/awsui-design-tokens';
import { useHistory } from "react-router-dom";
import React, { useRef, useState, useCallback } from 'react';
import Masthead, {
  MastheadTitle,
  MastheadLink,
} from "@amzn/meridian/masthead"
import Button from "@amzn/meridian/button"
import Icon from "@amzn/meridian/icon"
import helpTokens from "@amzn/meridian-tokens/base/icon/help"
import menuTokens from "@amzn/meridian-tokens/base/icon/menu"
import Menu, { MenuItem } from "@amzn/meridian/menu"
import Heading from "@amzn/meridian/heading"

export default function InnerHeader() {

  // selected header item
  const [selected, setSelected] = useState("")
  const onClickLink = useCallback(id => () => setSelected(id), [])
  const buttonRef = useRef()
  // open hamburger menu
  const [open, setOpen] = useState(false)
  const onClickButton = useCallback(() => setOpen(true), [])
  const onClose = useCallback(() => setOpen(false), [])

  // Update history when link is clicked
  const history = useHistory();
  function goToHistory() {
    history.push('/history');
  }
  function goToHelp() {
    history.push('/help');
  }
  function goToBusinessSelection() {
    setSelected("one");
    history.push('/');
  }
  function goToQuicksightProspero() {
    setSelected("two");
    history.push('/quicksightProspero');
  }
  function goToQuicksightBosco() {
    setSelected("three");
    history.push('/quicksightBosco');
  }
  function goToAsinDeepDive() {
    setSelected("four");
    history.push('/asindeepdive');
  }

  return (
    <Masthead>
      <MastheadTitle href="#landing">
        <Heading level={2}>
          ASIN Forecasting 
        </Heading>
      </MastheadTitle>
      <MastheadLink selected={selected === "one"} onClick={goToBusinessSelection}>
        Business
      </MastheadLink>
      <MastheadLink selected={selected === "two"} onClick={goToQuicksightProspero}>
        Prospero QS
      </MastheadLink>
      <MastheadLink selected={selected === "three"} onClick={goToQuicksightBosco}>
        Bosco QS
      </MastheadLink>
      <MastheadLink selected={selected === "four"} onClick={goToAsinDeepDive}>
        ASIN Deepdive
      </MastheadLink>


      <Button type="icon" size="small" onClick={goToHelp}>
        <Icon tokens={helpTokens}>Help</Icon>
      </Button>
      <React.Fragment>

        <Button
          type="icon"
          size="small"
          ref={buttonRef}
          onClick={onClickButton}
          aria-controls="my-menu"
          aria-haspopup="menu"
          aria-expanded="false"
        >
          <Icon tokens={menuTokens}>Menu</Icon>
        </Button>
        <Menu
          anchorNode={buttonRef.current}
          id="my-menu"
          open={open}
          position="bottom"
          onClose={onClose}
        >
          <MenuItem onClick={goToHistory}>History</MenuItem>
          <MenuItem onClick={goToHelp}>Help</MenuItem>
        </Menu>
      </React.Fragment>
    </Masthead>
  );
}