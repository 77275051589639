import React from 'react';
import Input from "@amzn/meridian/input";
import Column from "@amzn/meridian/column";

interface UserInputProps {
  setValue: (value: string) => void;
  value: string;
  label?: string;
  placeholder?: string;
}

const UserInput: React.FC<UserInputProps> = ({
  setValue,
  value,
  label = "Label",
  placeholder = "Enter value..."
}) => {
  return (
    <Column spacing="200">
      <Input
        id="user-input"
        value={value}
        onChange={setValue}
        type="text"
        placeholder={placeholder}
        label={label}
        size="xlarge"
        width={300}
      />
    </Column>
  );
};

export default UserInput;
