import { ActualsParams } from '../types/actuals';
import { ForecastParams } from '../types/forecast';
import { useActuals } from './useActuals';
import { useForecast } from './useForecast';

export const useForecastAndActuals = (
  paramsActual: ActualsParams, 
  paramsForecast: ForecastParams
) => {
  const { data: actualsData, isLoading: actualsLoading, error: actualsError } = useActuals(paramsActual);
  const { data: forecastData, isLoading: forecastLoading, error: forecastError } = useForecast(paramsForecast);

  return {
    actualsData,
    forecastData,
    isLoading: actualsLoading || forecastLoading,
    error: actualsError || forecastError,
    errors: {
      actuals: actualsError,
      forecast: forecastError
    }
  };
};
